import React from 'react';
import { graphql, Link } from 'gatsby';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'gatsby-plugin-intl';
import Img from 'gatsby-image';
import Layout from '../layouts/main/Layout';
import Collection from '../layouts/collection/Collection';


const PatternPost = ({ pageContext: { locale }, data }) => {
  const { markdownRemark: posts } = data;
  const link = locale === 'pl' ? `/${locale}` : '';
  return (
    <Layout locale={locale}>
      <Img fluid={posts.frontmatter.collectionImage.childImageSharp.fluid} alt={posts.collectionImageAlt} />
      <div className="pattern-post" dangerouslySetInnerHTML={{ __html: posts.html }} />
      <Collection patterns={posts} />
      <div>
        <h3>
          {' '}
          <FormattedMessage id="patterns.header" />
        </h3>
        <p>
          <FormattedMessage
            id="patterns.description"
            values={{
              url: (urlPlaceholder) => (
                <Link
                  to={`${link}/contact`}
                  className="link-pattern-contact"
                >
                  {urlPlaceholder}
                </Link>
              ),
            }}
          />
        </p>
      </div>
    </Layout>
  );
};

PatternPost.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string,
  }).isRequired,
};

export default PatternPost;

export const pageQuery = graphql`
  query PatternPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        path
        templateKey
        locale
        pageKey
        title
        slug
        mainImage {
              childImageSharp {
                fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
        mainImageAlt
        collectionImage {
              childImageSharp {
                fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
        collectionImageAlt
        horizontalOne {
              childImageSharp {
                fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
        horizontalOneAlt
        squareOne {
              childImageSharp {
                fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
        squareOneAlt
        squareTwo {
              childImageSharp {
                fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
        squareTwoAlt
        squareThree {
              childImageSharp {
                fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
        squareThreeAlt
        squareFour {
              childImageSharp {
                fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
        squareFourAlt
        squareFive {
              childImageSharp {
                fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
        squareFiveAlt
        squareSix {
              childImageSharp {
                fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
        squareSixAlt
        horizontalTwo {
              childImageSharp {
                fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
        horizontalTwoAlt
        verticalOne {
              childImageSharp {
                fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
        verticalOneAlt
        verticalTwo {
              childImageSharp {
                fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
        verticalTwoAlt
        horizontalThree {
              childImageSharp {
                fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
        horizontalThreeAlt
      }
    }
  }
`;
