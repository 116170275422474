/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

const Collection = ({ patterns }) => (
  <section className="grid-pattern">
    <Img className="square_one" fluid={patterns.frontmatter.squareOne.childImageSharp.fluid} alt={patterns.squareOneAlt} />
    <Img className="square_two" fluid={patterns.frontmatter.squareTwo.childImageSharp.fluid} alt={patterns.squareTwoAlt} />
    <Img className="square_three" fluid={patterns.frontmatter.squareThree.childImageSharp.fluid} alt={patterns.squareThreeAlt} />
    <Img className="square_four" fluid={patterns.frontmatter.squareFour.childImageSharp.fluid} alt={patterns.squareFourAlt} />
    <Img className="square_five" fluid={patterns.frontmatter.squareFive.childImageSharp.fluid} alt={patterns.squareFiveAlt} />
    <Img className="square_six" fluid={patterns.frontmatter.squareSix.childImageSharp.fluid} alt={patterns.squareSixAlt} />
    <Img className="horizontal_two" fluid={patterns.frontmatter.horizontalTwo.childImageSharp.fluid} alt={patterns.horizontalTwoAlt} />
    <Img className="vertical_one" fluid={patterns.frontmatter.verticalOne.childImageSharp.fluid} alt={patterns.verticalOneAlt} />
    <Img className="vertical_two" fluid={patterns.frontmatter.verticalTwo.childImageSharp.fluid} alt={patterns.verticalOneTwo} />
    <Img className="horizontal_three" fluid={patterns.frontmatter.horizontalThree.childImageSharp.fluid} alt={patterns.horizontalThreeAlt} />
  </section>
);
export default Collection;

Collection.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      grid: PropTypes.arrayOf(
        PropTypes.shape({
          key: PropTypes.string,
          alt: PropTypes.string,
          path: PropTypes.string,
          styling: PropTypes.string,
        }).isRequired,
      ).isRequired,
    }).isRequired,
  ).isRequired,
};
